<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column align-center">
              <eden-image-upload
                :image-url.sync="form.avatar"
                :image-folder="'customers'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Customer Details"
        subtitle="Let’s get to know this customer"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="first_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.first_name"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="last_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.last_name"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Email" prop="email" :rules="validateEmail()">
                <el-input
                  type="text"
                  v-model="form.email"
                  placeholder="you@mail.com"
                  :disabled="action === 'edit'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Phone number"
                prop="phoneNo"
                :rules="validatePhone('Phone number')"
              >
                <eden-phone-input :input.sync="form.phoneNo" />
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="action === 'edit'">
            <el-row type="flex" class="is-flex-wrap">
              <el-col :md="24">
                <el-form-item label="Date of birth">
                  <el-date-picker
                    v-model="form.birthday"
                    :clearable="false"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    prefix-icon="eden-icon--calendar"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Other"
        subtitle="Enter some extra information about this customer"
      >
        <template slot="form-fields">
          <el-row type="flex" class="is-flex-wrap" ref="preferences">
            <el-col :md="24">
              <el-form-item
                label="Address"
                prop="address"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.address"
                  placeholder="Enter the customer’s full address"
                  :disabled="timePastDeadline"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Landmark (optional)">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.landmark"
                  placeholder="Closest known location to the customers house"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Access note (optional)">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.access_note"
                  placeholder="Specific instructions on getting access to the customers house"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.food_notes" type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Food note (optional)">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.food_notes"
                  placeholder="General information about food"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="Location area"
                prop="location_area_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.location_area_id"
                  filterable
                  placeholder="Select location"
                >
                  <el-option
                    v-for="location in locations"
                    :key="location.id"
                    :label="location.name"
                    :value="location.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Gender"
                prop="gender"
                :rules="validateField()"
              >
                <el-select v-model="form.gender" placeholder="Select gender">
                  <el-option label="Male" value="male"></el-option>
                  <el-option label="Female" value="female"></el-option>
                  <el-option label="Non binary" value="non-binary"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Customer category"
                prop="customer_persona"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.customer_persona"
                  placeholder="Select a customer category"
                >
                  <el-option
                    v-for="(persona, i) in personas"
                    :key="i"
                    :label="formatText(persona.name)"
                    :value="persona.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Customer menu type">
                <!-- <el-select
                  v-model="form.menu_type_variation_id"
                  filterable
                  placeholder="Select a menu type"
                  value-key="id"
                >
                  <el-option
                    v-for="(item, index) in menuTypes"
                    :value="item.id"
                    :key="index"
                    :label="item.name"
                  ></el-option>
                </el-select> -->
                <el-select
                  v-model="form.menu_type_variation_id"
                  filterable
                  placeholder="Select a menu type"
                  value-key="id"
                >
                  <el-option
                    v-for="(item, index) in filteredMenuTypes"
                    :value="item.id"
                    :key="index"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        v-if="action === 'add'"
        title="Service Preferences"
        subtitle="Select the services this customer will be getting."
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Gardener in charge"
                prop="gardener_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.gardener_id"
                  placeholder="Select gardener"
                  filterable
                >
                  <el-option
                    v-for="(gardener, index) in gardeners"
                    :key="index"
                    :label="gardener.name"
                    :value="gardener.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label=""
                prop="services"
                :rules="validateField('Services')"
              >
                <subscription-configuration
                  @set-config="setSubscriptionConfiguration"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Start date"
                prop="start_date"
                :rules="validateField('Start date')"
              >
                <el-date-picker
                  v-model="form.start_date"
                  value-format="yyyy-MM-dd"
                  type="date"
                  format="dddd, dd MMMM, yyyy"
                  prefix-icon="eden-icon-calendar"
                  :picker-options="{
                    disabledDate: disabledDates,
                  }"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="$router.go(-1)">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add Customer
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import individual from "@/requests/customers/individual";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerIndividualForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        avatar: this.getDefaultAvatarUrl(),
        first_name: "",
        last_name: "",
        email: "",
        phoneNo: "",
        address: "",
        landmark: "",
        access_note: "",
        location_area_id: "",
        gender: "",
        customer_persona: "",
        role: "customer",
        gardener_id: "",
        services: [],
        amount: 0,
        plan: {},
        start_date: "",
        menu_type_variation_id: "",
      },
      personas: [],
      uploadingPhoto: false,
      adding: false,
      updating: false,
    };
  },
  computed: {
    menuTypes() {
      return this.$store.getters.menu_types;
    },
    customerId() {
      return this.$route.params.id;
    },
    locations() {
      return this.$store.getters.location_areas_list;
    },
    services() {
      return ["Cleaning", "Laundry", "Meal"];
    },
    gardeners() {
      const gardeners = [...new Set(this.$store.getters.gardeners_list)];
      return this.sortList(gardeners, "name");
    },
    timePastDeadline() {
      if (this.allowAccessFor(["gardener", "gardener_pro"], true)) {
        var today = new Date();
        today.setHours(17);
        today.setMinutes(30);

        return (
          this.getDateDifference({
            fDate: new Date().toISOString(),
            sDate: today.toISOString(),
            unit: "second",
          }) > 0
        );
      }

      return false;
    },
    filteredMenuTypes() {
      return this.menuTypes.filter((item) => item.name !== "Basic");
    },
  },
  watch: {
    loading() {
      if (this.action === "edit" && !this.loading) {
        this.form = this.customer;
      }
    },
  },
  async created() {
    this.getPersonas();
    await this.$store.dispatch(actions.GET_MENU_TYPES);
  },
  methods: {
    getPersonas() {
      individual
        .personas()
        .then((response) => {
          if (response.data.status) {
            this.personas = response.data.data;
          }
        })
        .catch();
    },
    setSubscriptionConfiguration({ config, amount }) {
      this.form.services = Object.keys(config);
      this.form.plan = config;
      this.form.amount = amount;
    },
    disabledDates(time) {
      if (this.allowAccessFor([])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    formatForm() {
      const payload = { ...this.form };

      payload.avatar = payload.avatar || this.getAvatar();
      payload.full_name = payload.first_name + " " + payload.last_name;

      payload.amount = payload.services.reduce((total, service) => {
        return total + payload.plan[service].amount;
      }, 0);

      const sortedPlan = this.sortObject(payload.plan);
      payload.plan = JSON.stringify(sortedPlan);
      payload.new_onboarding_flow = true;

      return payload;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.adding = true;
        const payload = {
          ...this.formatForm(),
          is_created_via_lighthouse: true,
        };

        individual
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.$router.push({ name: "customers.index" });
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;

        let payload = { ...this.form };

        payload.full_name = payload.first_name + " " + payload.last_name;
        payload.home_address = payload.address;
        payload.phone_number = payload.phoneNo;
        payload.avatar = payload.avatar || this.getAvatar();

        individual
          .update(this.customerId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$router.push({
                name: "customers.individual",
                params: { id: this.customerId },
              });
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
