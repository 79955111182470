<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="'Individual - Add new customer'"
    />
    <customer-individual-form action="add" />
  </div>
</template>

<script>
import CustomerIndividualForm from "@/components/Customers/Individual/CustomerIndividualForm.vue";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerIndividualAdd",
  components: {
    CustomerIndividualForm,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(actions.GET_GARDENERS_LIST);
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>

<style scoped lang="scss"></style>
